import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

const ReactHookFormInput = ({
  name,
  label,
  register,
  defaultValue,
  multiline,
  type,
  rules,
  placeholder,
  ...props
}) => {
  const labelid = `${name}-label`;
  const height = multiline ? "92px" : "46px";
  return (
    <FormControl sx={{ width: "100%" }} {...props}>
      <TextField
        sx={{
          ".MuiInputBase-root": {
            height: height,
            marginTop: "40px",
          },
          ".MuiInputLabel-root": {
            "-webkit-transform": "translate(14px, 52px) scale(1)",
            "-moz-transform": "translate(14px, 52px) scale(1)",
            "-ms-transform": "translate(14px, 52px) scale(1)",
            transform: "translate(14px, 52px) scale(1)",
            color: "#040c18",
            "&.Mui-focused": {
              "-webkit-transform": "translate(14px, 32px) scale(0.75)",
              "-moz-transform": "translate(14px, 32px) scale(0.75)",
              "-ms-transform": "translate(14x, 32px) scale(0.75)",
              transform: "translate(14px, 32px) scale(0.75)",
              color: "#040c18",
            },
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderWidth: "2px",
            borderStyle: "inset",
            borderColor: "rgb(133, 133, 133)",
          },
          ".MuiOutlinedInput-root": {
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ff7b5d",
            },
          },
          ".MuiInputLabel-shrink": {
            "-webkit-transform": "translate(14px, 32px) scale(0.75)",
            "-moz-transform": "translate(14px, 32px) scale(0.75)",
            "-ms-transform": "translate(14x, 32px) scale(0.75)",
            transform: "translate(14px, 32px) scale(0.75)",
            color: "#040c18",
          },
        }}
        labelid={labelid}
        label={label}
        multiline={multiline}
        type={type}
        placeholder={placeholder}
        {...register(name, { ...rules })}
      />
    </FormControl>
  );
};

export default ReactHookFormInput;
