export const galleryImages = [
  {
    src: require("../../assets/gallery/kids-3.jpg"),
    alt: "Children finished their yearly course",
    title: "Kids celebrating their graduation.",
  },
  {
    src: require("../../assets/gallery/new-office-1.webp"),
    alt: "Bratilingua's new office",
    title: "Bratilingua's new office",
  },
  {
    src: require("../../assets/gallery/summer-camp-2024.webp"),
    alt: "Summer camp 2024 edition - children's drawings",
    title: "Summer camp 2024 edition - children's drawings",
  },
];
