import React from 'react';
import Form from "../form/Form";
import { useForm } from "react-hook-form";
import 'react-toastify/dist/ReactToastify.css';
import "./contactPage.css";

const ContactPageContent = () => {
  const {register, handleSubmit, formState: { errors }, setValue, reset, watch} = useForm();

  return (
    <div className='bl_contact-wrapper section__padding-page'>
      <h1>Registrácia</h1>
      <p>Ak vás zaujala ponuka našich kurzov, vyplňte náš registračný formulár. Po odoslaní formuláru a zaplatení poplatku za kurz, vás budeme konktatovať a s lekciami môžete začať ihneď!</p>
      <div className='bl_contact-option div_shadow'>
        <h3>Čím viac hodín objednáš, tým viac ušetríš!</h3>
        <Form 
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          setValue={setValue}
          reset={reset}
          watch={watch}
        />
      </div>
    </div>
  )
}

export default ContactPageContent
