import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import App from "./App";
import ContactPage from "./pages/ContactPage";
import AboutPage from "./pages/AboutPage";
import CourseEnglishPage from "./pages/CourseEnglishPage";
import CourseSpanishPage from "./pages/CourseSpanishPage";
import GdprPage from "./pages/GdprPage";
import GalleryPage from "./pages/GalleryPage";
import NoutFoundPage from "./pages/NotFoundPage";
import CampPage from "./pages/CampPage";
import "./index.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/o-nas",
    element: <AboutPage />,
  },
  {
    path: "/registracia",
    element: <ContactPage />,
  },
  {
    path: "/kurzy/anglicky-jazyk",
    element: <CourseEnglishPage />,
  },
  {
    path: "/kurzy/spanielsky-jazyk",
    element: <CourseSpanishPage />,
  },
  {
    path: "/galeria",
    element: <GalleryPage />,
  },
  {
    path: "/letny-tabor",
    element: <CampPage />,
  },
  {
    path: "/gdpr",
    element: <GdprPage />,
  },
  {
    path: "*",
    element: <NoutFoundPage />,
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
