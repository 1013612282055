export const teacherImages = [
  {
    name: "Karin",
    captionTitle: "Študijná riaditeľka",
    captionDescription:
      "Karin vyštudovala anglický a španielsky jazyk a ich výučbe sa venuje už niekoľko rokov. Vyučuje najmä pokročilejších študentov a firemné kurzy.",
    src: require("../../assets/teachers/karin.jpg"),
    alt: "učiteľka angličtiny a španielčiny - Karin",
    title: "Picture of our english - spanish teacher Karin.",
  },
  {
    name: "Paulína",
    captionTitle: "Lektorka",
    captionDescription:
      "Paula vyštudovala odbor prekladateľstvo a tlmočníctvo v kombinácii anglický a španielsky jazyk. Má bohaté skúsenosti s vedením detských jazykových kurzov, ale do tajov angličtiny a španielčiny rada zasvätí aj dospelých.",
    src: require("../../assets/teachers/paula.jpg"),
    alt: "učiteľka angličtiny a španielčiny - Paulina",
    title: "Picture of our english - spanish teacher Paulina.",
  },
  {
    name: "Natália",
    captionTitle: "Lektorka",
    captionDescription:
      "Natália je študentkou práva v Bratislave. Jej veľkým záujmom už od detstva je učenie sa cudzích jazykov. Na svojich hodinách používa moderné učebné metódy, ktoré už pomohli mnohým študentom naučiť sa po anglicky a rada pomôže aj tebe.",
    src: require("../../assets/teachers/natalia.jpg"),
    alt: "učiteľka angličtiny - Natália",
    title: "Picture of our english teacher Natália.",
  },
  {
    name: "Monika",
    captionTitle: "Lektorka",
    captionDescription:
      "Monika aktuálne študuje na vysokej škole ekonómie a manažmentu a ovláda španielčinu aj angličtinu. Skúsenosti so španielčinou nadobudla priamo v Španielsku, kde dlhú dobu žila a angličtina je jej srdcovka.",
    src: require("../../assets/teachers/monika.jpg"),
    alt: "učiteľka angličtiny - Monika",
    title: "Picture of our english teacher Monika.",
  },
  {
    name: "Mária",
    captionTitle: "Lektorka",
    captionDescription:
      "Mária sa venuje výučbe anglického jazyka v škôlkach a rodinnom centre v Petržalke. Zameriava sa najmä na mladšie deti.",
    src: require("../../assets/teachers/maja.jpeg"),
    alt: "učiteľka angličtiny - Mária",
    title: "Picture of our english teacher Mária.",
  },
  {
    name: "Tímea",
    captionTitle: "Lektorka",
    captionDescription:
      "Tímea študuje aplikované jazyky a preto ťa môže naučiť nie len po anglicky, ale aj po španielsky. Pracuje s deťmi a dospelými.",
    src: require("../../assets/teachers/timea.jpeg"),
    alt: "učiteľka angličtiny a španielčiny - Tímea",
    title: "Picture of our english - spanish teacher Tímea.",
  },
];
