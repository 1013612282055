import React, { useState } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import logo from "../../assets/Bratilingua-abbr-white.svg";
import { menuItems } from "./menuItems";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { scrollToTop } from "../../utils/utils";
import "./navbar.css";
import MyLazyLoadedImage from "../../components/lazyImageLoader/lazyImageLoader";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="bl__navbar">
      <div className="bl__navbar-links">
        <div className="bl__navbar-links_logo">
          <Link to="/" onClick={scrollToTop}>
            <MyLazyLoadedImage imageSrc={logo} altText="Bratilingua logo" />
          </Link>
        </div>
        <div className="bl__navbar-links_container">
          {menuItems.map((menuItem) => {
            if (menuItem.submenu) {
              return (
                <div key={menuItem.title}>
                  <Link to={menuItem.url} onClick={scrollToTop}>
                    {menuItem.title}
                  </Link>
                  <ul className="bl__navbar-links_container-submenu">
                    {menuItem.submenu.map((submenuItem) => (
                      <li key={submenuItem.title}>
                        <Link to={submenuItem.url} onClick={scrollToTop}>
                          {submenuItem.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              );
            } else {
              return (
                <div key={menuItem.title}>
                  <Link to={menuItem.url} onClick={scrollToTop}>
                    {menuItem.title}
                  </Link>
                </div>
              );
            }
          })}
        </div>
      </div>
      <div className="bl__navbar-sign">
        <Link to={"/registracia"} onClick={scrollToTop}>
          <button className="button-primary" type="button">
            Ukážková hodina ZDARMA!
          </button>
        </Link>
      </div>
      <div className="bl__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="bl__navbar-menu_container">
            <div className="bl__navbar-menu_container-links">
              {menuItems.map((menuItem) => {
                if (menuItem.submenu) {
                  return (
                    <div key={menuItem.title}>
                      <Link to={menuItem.url} onClick={scrollToTop}>
                        {menuItem.title}
                      </Link>
                      {menuItem.submenu.map((submenuItem) => (
                        <li
                          className="bl__navbar-links_container-submenu"
                          key={submenuItem.title}
                        >
                          <FontAwesomeIcon icon="fa-regular fa-square-caret-right" />
                          <Link to={submenuItem.url} onClick={scrollToTop}>
                            {submenuItem.title}
                          </Link>
                        </li>
                      ))}
                    </div>
                  );
                } else {
                  return (
                    <div key={menuItem.title}>
                      <Link to={menuItem.url} onClick={scrollToTop}>
                        {menuItem.title}
                      </Link>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
