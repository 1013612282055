import React from "react";
import {
  MDBCarousel,
  MDBCarouselItem,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import "./reviews.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Review = () => {
  return (
    <MDBContainer className="section_shadow">
      <MDBCarousel dark showIndicators touch interval={6000}>
        <MDBCarouselItem
          itemId={1}
          className="text-center d-flex justify-content-center"
        >
          <MDBRow className="d-flex justify-content-center">
            <MDBCol lg="8">
              <h5 className="mb-3">Ivona G.</h5>
              <p className="text-muted">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                Kristína je príjemná lektorka, flexibilný rozvrh, individuálne
                hodiny online anglického jazyka. Teória kvalitne, komplexne
                vysvetlená s použitím praktických príkladov + upevnenie
                vedomosti školského učiva. Trpezlivosť, schopnosť udržiavať
                pozornosť dcéry.
              </p>
            </MDBCol>
          </MDBRow>
          <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
          </ul>
        </MDBCarouselItem>

        <MDBCarouselItem itemId={2} className="text-center">
          <MDBRow className="d-flex justify-content-center">
            <MDBCol lg="8">
              <h5 className="mb-3">Renáta L.</h5>
              <p className="text-muted">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                Monika sa nedá porovnať s inými lektormi, pretože učenie má
                naozaj v sebe. Mala som už viacero doučovateľov a vidím veľký
                rozdiel.Je iné keď sa niekto naučí jazyk a učí ho ďalej, a keď
                niekto jazykom žije.
              </p>
            </MDBCol>
          </MDBRow>
          <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
          </ul>
        </MDBCarouselItem>

        <MDBCarouselItem itemId={3} className="text-center">
          <MDBRow className="d-flex justify-content-center">
            <MDBCol lg="8">
              <h5 className="mb-3">Veronika H.</h5>
              <p className="text-muted">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                Karin je veľmi milá a ochotná. Má perfekntý systém učenia.
              </p>
            </MDBCol>
          </MDBRow>
          <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
          </ul>
        </MDBCarouselItem>

        <MDBCarouselItem itemId={4} className="text-center">
          <MDBRow className="d-flex justify-content-center">
            <MDBCol lg="8">
              <h5 className="mb-3">Marek P.</h5>
              <p className="text-muted">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                Najlepšia angličtina akú som kedy zažil. TOP!!!
              </p>
            </MDBCol>
          </MDBRow>
          <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
          </ul>
        </MDBCarouselItem>

        <MDBCarouselItem itemId={5} className="text-center">
          <MDBRow className="d-flex justify-content-center">
            <MDBCol lg="8">
              <h5 className="mb-3">Janka K.</h5>
              <p className="text-muted">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                Maximálna spokojnosť. Veľmi príjemná a milá lektorka.
                Individuálne hodiny prebiehajú podľa mojej potreby, veľa
                konverzácie a počúvania v angličtine. Zábavné a zaujímavé témy.
                Odporúčam.
              </p>
            </MDBCol>
          </MDBRow>
          <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
            <li>
              <MDBIcon fas icon="star" size="sm" />
            </li>
          </ul>
        </MDBCarouselItem>
      </MDBCarousel>
    </MDBContainer>
  );
};

export default Review;
