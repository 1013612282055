import React from "react";
import { Icon } from "@iconify/react";
import locationIcon from "@iconify/icons-mdi/map-marker";
import "./map.css";
import { Link } from "react-router-dom";

const LocationPin = ({ text }) => {
  return (
    <div className="pin">
      <Icon icon={locationIcon} className="pin-icon" />
      <Link to="https://maps.app.goo.gl/RQXhBk3Y3emwDs13A" target="_blank"><p className="pin-text">{text}</p></Link>
    </div>
  );
};

export default LocationPin;
