const photos = [
    {
        src: require("../../assets/gallery/Petrzalka-leaflet.jpg"),
        width: 625,
        height: 888,
        title: "https://www.centrumprerodiny.sk/"
    },
    {
        src: require("../../assets/gallery/bratilingua-new-office-outside.jpg"),
        width: 800,
        height: 600,
        title: false
    },
    {
        src: require("../../assets/gallery/BL_client_in_office_1.jpg"),
        width: 700,
        height: 933,
        title: false
    },
    {
        src: require("../../assets/gallery/kids-1.jpg"),
        width: 614,
        height: 461,
        title: false
    },
    {
        src: require("../../assets/gallery/kids-2.jpg"),
        width: 614,
        height: 461,
        title: false
    },
    {
        src: require("../../assets/gallery/summer-camp-2024.webp"),
        width: 200,
        height: 267,
        title: false
    },
    {
        src: require("../../assets/gallery/kids-3.jpg"),
        width: 590,
        height: 709,
        title: false
    },
    {
        src: require("../../assets/gallery/kids-4.jpg"),
        width: 614,
        height: 461,
        title: false
    },
    {
        src: require("../../assets/gallery/new-office-1.webp"),
        width: 200,
        height: 267,
        title: false
    },
    {
        src: require("../../assets/gallery/new-office-2.webp"),
        width: 200,
        height: 267,
        title: false
    },
    {
        src: require("../../assets/gallery/new-office-3.webp"),
        width: 200,
        height: 267,
        title: false
    },
];

export default photos;