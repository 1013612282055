import React from 'react';
import CampPageContent from './../components/campPage/campPageContent';
import { Navbar } from "../components";
import { Contact } from "../containers";

const CampPage = () => {
  return (
    <div>
    <div className="z-top">
      <Navbar />
    </div>
    <CampPageContent />
    <Contact />
  </div>
  )
}

export default CampPage